<template>
    <div>
        <template v-if="detect == 'pc'">
            <v-list flat no-action class="aside-list">
                <template v-for="item in items">
                    <v-list-item v-if="!item.children" :key="item.text" :to="item.to" exact exact-active-class="aside-list--active" :class="{ 'aside-list--active': item.isActive }">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                    <v-list-group v-else no-action :value="true" :key="item.text">
                        <template v-slot:activator>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </template>

                        <v-list-item v-for="child in item.children" :key="child.text" :to="child.to" exact exact-active-class="aside-list--active" :class="{ 'aside-list--active': child.isActive }">
                            <v-list-item-title>{{ child.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </template>
            </v-list>
        </template>
        <template v-if="detect == 'mobile'">
            <v-breadcrumbs :items="breadcrumbsItems" class="d-lg-none">
                <template #divider>
                    <v-icon small>mdi-chevron-right</v-icon>
                </template>
                <template #item="{ item }">
                    <template v-if="item.text == 'HOME'">
                        <v-breadcrumbs-item to="/" exact>
                            <v-icon class="font-size-20">mdi-home-outline</v-icon>
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else-if="item.text == 'Category'">
                        <v-breadcrumbs-item to="/shop/products" exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else>
                        <v-breadcrumbs-item @click="siblingMenu !== item.sibling ? (siblingMenu = item.sibling) : (siblingMenu = undefined)">
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </template>
            </v-breadcrumbs>
            <template v-for="(item, index) in breadcrumbsItems">
                <v-expand-transition v-if="item.sibling" :key="item.text">
                    <v-list v-show="siblingMenu === item.sibling" class="breadcrumbs-list">
                        <template v-for="item in item.sibling">
                            <v-list-item exact :to="{ ...(item?.value || {}), query: { ...$route.query, ...(item?.value?.query || {}) } }" :key="item._id" @click.prevent="((siblingMenu = undefined) || true) && $router.push({ ...(item?.value || {}), query: item?.value?.query || {} })" class="font-size-14">
                                {{ item.text }}
                            </v-list-item>
                        </template>
                    </v-list>
                </v-expand-transition>
            </template>
        </template>
    </div>
</template>

<script>
import detect from "@/plugins/detect";

export default {
    props: {
        items: { type: Array, default: () => [] },
    },
    setup: () => ({
        detect,
    }),
    data: () => ({
        siblingMenu: undefined,
    }),
    computed: {
        breadcrumbsItems() {
            const items = this.items.map(this.mapBreadcrumItem);
            console.log({ items });

            const breadcrumbsItems = function recursiveFinder(/** @type {array} */ breadcrumbsItems, /** @type {import("@/store/ui").GnbItem[]} */ items) {
                for (const item of items) {
                    if (!this.getIsActive(item)) continue;

                    breadcrumbsItems.push({
                        ...item,
                        sibling: items,
                    });

                    if ((item.children || []).length < 1) return breadcrumbsItems;

                    return recursiveFinder.bind(this)(breadcrumbsItems, item.children);
                }
                return breadcrumbsItems;
            }.bind(this)([{ text: "HOME", to: "/" }], items);

            return breadcrumbsItems;
        },
    },
    methods: {
        mapBreadcrumItem(item = {}) {
            if (item.to) {
                item.value = { ...item.to };
                delete item.to;
            }

            if (item.children) {
                item.children = item.children.map(this.mapBreadcrumItem);
            }

            return item;
        },
        getIsActive(item) {
            const samePath = this.$route.path.includes(item?.value?.path);
            const sameQuery = !Object.keys(item?.value?.query || {}).some((key) => this.$route.query[key] != item?.value?.query?.[key]);
            if (samePath && sameQuery) return true;

            if (item.children) {
                const childActive = item.children.some(this.getIsActive);
                if (childActive) return true;
            }

            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.aside-list {
    background-color: var(--v-grey-lighten5);
    padding: 0;
    ::v-deep {
        .v-list-item {
            min-height: 28px;
            font-size: 14px !important;
            color: var(--v-grey-base);
            padding: 0 16px !important;
            &__title {
                font-size: inherit;
            }
        }
        > .v-list-item,
        > .v-list-group {
            padding: 10px 0;
            border-bottom: 1px solid var(--v-grey-lighten4);
        }
        > .v-list-item,
        > .v-list-group > .v-list-group__header {
            font-weight: 700;
            color: var(--v-grey-darken4);
        }
        > .v-list-group {
            > .v-list-group__header {
                pointer-events: none;
                cursor: default;
                .v-icon {
                    display: none;
                }
            }
            .aside-list {
                &--active {
                    font-weight: 400 !important;
                    color: var(--v-grey-darken4) !important;
                    text-decoration: underline;
                }
            }
        }
        > .v-list-item {
            min-height: 54px;
        }
    }

    &--active {
        font-weight: 700 !important;
        > .v-list-item__title {
            color: var(--v-primary-base) !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .aside-list {
        ::v-deep {
            > .v-list-item,
            > .v-list-group {
                padding: 20px 0;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

@media (max-width: 1400px) {
    .aside-list {
        &--row {
            ::v-deep {
                .v-list-group__items {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                }
                .v-list-item {
                    flex: 0 1 auto;
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .aside-list {
        &--row {
            ::v-deep {
                .v-list-item {
                    flex: 1 1 100%;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .aside-list {
        &--row {
            ::v-deep {
                .v-list-item {
                    flex: 0 1 auto;
                }
            }
        }
    }
}

// breadcrumbs
.breadcrumbs-list.theme--light {
    display: flex;
    flex-wrap: wrap;
    border-color: var(--v-primary-base);
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 0;
    .v-list-item {
        width: calc(100% / 2);
        flex: initial;
        justify-content: center;
        position: relative;
        overflow: hidden;
        &::before {
            opacity: 0;
        }
        &::after {
            position: absolute;
            bottom: 0;
            width: 300%;
            height: 1px;
            min-height: initial;
            background-color: var(--border-color);
        }
    }
}
</style>
