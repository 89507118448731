<template>
    <v-card tile tag="aside" width="100%" class="overflow-hidden">
        <aside-user-card />
        <aside-navigation v-if="navigation || $vuetify.breakpoint.mdAndUp" v-bind="{ items }" />
        <v-layout v-if="!$vuetify.breakpoint.mdAndUp" justify-center>
            <v-btn icon large outlined color="grey lighten-3" class="aside-btn" @click.stop="navigation = !navigation">
                <v-icon v-if="navigation">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
        </v-layout>
    </v-card>
</template>

<script>
import AsideUserCard from "@/components/client/dumb/aside-user-card.vue";
import AsideNavigation from "@/components/client/dumb/aside-navigation.vue";

export default {
    components: {
        AsideUserCard,
        AsideNavigation,
    },
    data: () => ({
        navigation: false,
    }),
    computed: {
        items() {
            return [
                { text: "대시보드", to: { path: "/mypage/dashboard" } },
                {
                    text: "쇼핑정보",
                    children: [
                        {
                            text: "주문내역",
                            to: { path: "/mypage/orders" },
                        },
                        {
                            text: "찜한상품",
                            to: { path: "/mypage/likes" },
                        },
                        {
                            text: "장바구니",
                            to: { path: "/mypage/cart" },
                        },
                        {
                            text: "취소/교환/반품",
                            to: { path: "/mypage/orders?code=claimProcessGoing" },
                        },
                        // {
                        //     text: "영수증 신청/발급",
                        //     to: { path: "/mypage/receipt" },
                        // },
                    ],
                },
                {
                    text: "개인결제",
                    to: { path: "/mypage/target-payments" },
                    isActive: this.$route.path.includes("/mypage/target-payments"),
                },
                {
                    text: "혜택정보",
                    children: [
                        {
                            text: "적립금 내역",
                            to: { path: "/mypage/points" },
                        },
                        {
                            text: "쿠폰 내역",
                            to: { path: "/mypage/coupons" },
                        },
                    ],
                },
                {
                    text: "회원정보 관리",
                    children: [
                        {
                            text: "회원정보 수정",
                            to: { path: "/mypage/myinfo" },
                        },
                        {
                            text: "배송지 정보",
                            to: { path: "/mypage/destinations" },
                        },
                        // {
                        //     text: "환불계좌 관리",
                        //     to: { path: "/mypage/refund-account" },
                        // },
                        {
                            text: "회원탈퇴",
                            to: { path: "/mypage/leave" },
                        },
                    ],
                },
                {
                    text: "고객센터",
                    children: [
                        {
                            text: "나의 리뷰",
                            to: { path: "/mypage/reviews" },
                        },
                        {
                            text: "나의 상품 Q&A",
                            to: { path: "/mypage/inquiries" },
                        },
                        {
                            text: "1:1 문의 내역",
                            to: { path: "/mypage/questions" },
                        },
                        {
                            text: "기타 문의 내역",
                            to: { path: "/mypage/forms" },
                        },
                    ],
                },
                {
                    text: "대량구매요청",
                    to: { path: "/mypage/bulk-purchase" },
                    isActive: this.$route.path.includes("/mypage/bulk-purchase"),
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.aside-btn {
    --btn-outline-border-width: 1px;
    &.v-btn.v-btn--outlined.v-btn--icon.v-size--large {
        border-top: 0;
        border-radius: 0 0 6px 6px;
        height: calc(var(--btn-height-lg) / 2) !important;
        .v-icon {
            color: var(--v-grey-base);
        }
    }
}
</style>
