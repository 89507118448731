var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "tag": "aside",
      "width": "100%"
    }
  }, [_c('aside-user-card'), _vm.navigation || _vm.$vuetify.breakpoint.mdAndUp ? _c('aside-navigation', _vm._b({}, 'aside-navigation', {
    items: _vm.items
  }, false)) : _vm._e(), !_vm.$vuetify.breakpoint.mdAndUp ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-btn', {
    staticClass: "aside-btn",
    attrs: {
      "icon": "",
      "large": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.navigation = !_vm.navigation;
      }
    }
  }, [_vm.navigation ? _c('v-icon', [_vm._v("mdi-chevron-up")]) : _c('v-icon', [_vm._v("mdi-chevron-down")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }